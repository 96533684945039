$('[data-toggle="search"]').click(function(){
	var bgSearch = document.createElement('bg-buscas');
	var topo = document.querySelector('.topo');

	$(bgSearch).addClass('bg-buscas');

	$(bgSearch).click(function(){
		$('.buscas').removeClass('active');

		$('.bg-buscas').remove();
	});

	topo.appendChild(bgSearch);

	$('.buscas').addClass('active');
});