let LazyBg = (function(){
	let jaLazy = false;

	function lzBg(){
		let lz = $('[data-lazy-bg]');
		let wh = (window.innerHeight + 200);

		if(lz.length > 0){
			lz.each(function(){
				if(this.getBoundingClientRect().top <= wh){
					let bg = $(this).data('lazy-bg');

					$(this).css('background-image',`url(${bg})`);
					$(this).removeAttr('data-lazy-bg');
				}
			});
		}
	}

	$(window).scroll(function(){
		if(jaLazy) return;

		setTimeout(function(){
			jaLazy = false;
		},100);

		lzBg();
	});

	return lzBg();
})();