let fxMblCtrls = (function(){
	let fxTopo = $('.faixa-topo');
	let jaFx = false;

	function isMblRect(){
		if($(window).scrollTop() >= fxTopo.outerHeight()){
			$('.topo').css('height', `${$('.topo').outerHeight()}px`);
			$('.mobile-controls').addClass('fx');
		}else{
			$('.topo').removeAttr('style');
			$('.mobile-controls').removeClass('fx');
		}
	}

	$(window).scroll(function(){
		if(jaFx) return;

		setTimeout(function(){
			jaFx = false;
		}, 100);

		isMblRect();
	});

	return isMblRect();
})();