$('[data-toggle="menu"]').click(function(){
	var bgMenu = document.createElement('bg-menu');
	var topo = document.querySelector('.topo');

	$(bgMenu).addClass('bg-menu');

	$(bgMenu).click(function(){
		$('.bg-menu').remove();
		$('.main-topo').removeClass('active');
	});

	topo.appendChild(bgMenu);
	$('.main-topo').addClass('active');
});