function onOwlCarouselReady(){

	$('.owl-carousel').each(function(){
		let responsiveList 	= {};

		responsiveList.xs 	= $(this).data('xs') || 1;
		responsiveList.sm 	= $(this).data('sm') || responsiveList.xs;
		responsiveList.md 	= $(this).data('md') || responsiveList.sm;
		responsiveList.lg 	= $(this).data('lg') || responsiveList.ms;
		responsiveList.xl 	= $(this).data('xl') || responsiveList.lg;

		let childrenLength 	= $(this).children().length;
		let margin 			= $(this).data('margin');
		let autoplay 		= $(this).data('autoplay') == 'true' ? true : false;
		let loop 			= $(this).data('loop');
		let responsiveLoop 	= {
			xs : loop != false ? childrenLength > responsiveList.xs  : false,
			sm : loop != false ? childrenLength > responsiveList.sm  : false,
			md : loop != false ? childrenLength > responsiveList.md  : false,
			lg : loop != false ? childrenLength > responsiveList.lg  : false,
			xl : loop != false ? childrenLength > responsiveList.xl  : false
		}

		$(this).owlCarousel({
			autoplay	: autoplay,
			responsive 	: {
				0 : {
					items 	: responsiveList.xs,
					loop  	: responsiveLoop.xs,
					margin	: margin || 0
				},
				576 : {
					items 	: responsiveList.sm,
					loop  	: responsiveLoop.sm,
					margin	: margin || 0
				},
				768 : {
					items 	: responsiveList.md,
					loop  	: responsiveLoop.md,
					margin	: margin || 0
				},
				992 : {
					items 	: responsiveList.lg,
					loop  	: responsiveLoop.lg,
					margin	: margin || 0
				},
				1200 : {
					items 	: responsiveList.xl,
					loop  	: responsiveLoop.xl,
					margin	: margin || 0
				},
			}
		});
	});


	$('[data-owl]').click(function(e){
		e.preventDefault();

		let owl 	= $(this).data('owl');
		let target	= $(this).attr('href');

		$(target).trigger(owl+'.owl.carousel');
	});
}